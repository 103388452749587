import React from "react"
import { Carousel } from "../../Shared"
import { Image } from "../../Global/Gui/Image"
import { Button } from "../../Global/Gui/Buttons"

const CTA = ({ data }) => {
  const {
    header,
    description,
    primaryUrl,
    primaryCtaText,
    gallery,
  } = data
  return (
    <div class="bg-white">
      <div class="grid grid-cols-1 lg:grid-cols-2 gap-12 container xl:max-w-7xl mx-auto px-4 py-16 lg:px-8 lg:py-32">
        <div className={`w-full max-w-36-rem m-0-auto`}>
          {gallery && gallery.length === 1 && (
            <Image image={gallery[0].localFile.childImageSharp.gatsbyImageData}/>
          )}
          {gallery && gallery.length > 1 && <Carousel images={gallery} />}
        </div>{" "}
        <div class="flex items-center text-center lg:text-left">
          <div class="space-y-10">
            <div>
              <div class="text-sm uppercase font-bold tracking-wider mb-1 text-blue-400">
                Handmade
              </div>
              <h2 class="text-3xl md:text-4xl font-extrabold mb-4">{header}</h2>
              <h3 class="text-lg md:text-xl md:leading-relaxed font-medium text-gray-600">
                {description.description}
              </h3>
            </div>
            <div>
              {primaryUrl ? (
                <Button href={primaryUrl} theme={`LAUNCH-DARK`} >
                  {primaryCtaText ? (
                    <span>{primaryCtaText}</span>
                  ) : (
                    <span>"Learn More"</span>
                  )}
                  <svg
                    class="hi-solid hi-arrow-narrow-right inline-block w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </Button>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CTA
