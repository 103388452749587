import { graphql } from "gatsby"
import React from "react"
import { Hero, TopProducts, CTA } from "../../src/components/PageSpecific/homepage"
import { PageLayout } from "../components/Global/Layouts"
import { SiteMetadata } from "../components/Shared"

const IndexPage = ({ data }) => {
  const { hero, cta } = data
  console.log(data)
  return (
    <PageLayout>
      <SiteMetadata title="Home" description="Welcome to AnnMade!" />
      <Hero data={hero} />
      <TopProducts data={data} />
      <CTA data={cta}/>
    </PageLayout>
  )
}

export default IndexPage

export const query = graphql`
  query HomeQuery {
    hero: contentfulPageHero(uuid: {eq: "homepage-hero"}) {
      header
      description {
        description
      }
      primaryCtaText
      primaryUrl
      secondaryCtaText
      secondaryUrl
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }
    }
    contentItems: allContentfulShopifyItems(filter: {node_locale: {eq: "en-US"}, shopifyId: {nin: ["Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzYyNTk2MzU5NDU2MjM="]}}) {
      nodes {
        thumbnail {
          id
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        shopifyId
        name
        slug
        summary
      }
    }
    cta: contentfulCtaFeaturedImage(uuid: { eq: "homepage-cta-about" }) {
      header
      description {
        description
      }
      gallery {
        id
        localFile {
          childImageSharp {
            gatsbyImageData(width: 960, quality: 85, layout: CONSTRAINED)
          }
        }
        title
      }
      primaryCtaText
      primaryUrl
    }
  }
`
