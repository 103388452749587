import React from "react"
import { Image } from "../../Global/Gui/Image"
import { Button } from '../../Global/Gui/Buttons'

const Hero = ({ data }) => {
  const {
    header,
    description,
    primaryUrl,
    primaryCtaText,
    secondaryUrl,
    secondaryCtaText,
    image,
  } = data
  return (
    <div class="bg-white">
      <div class="space-y-16 container xl:max-w-7xl mx-auto px-4 py-16 lg:px-8 lg:py-32">
        <div class="text-center">
          <svg class="text-center text-annmade-1.0-blue mb-5 hi-outline hi-annotation inline-block w-24 h-24" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.5 2a3.5 3.5 0 101.665 6.58L8.585 10l-1.42 1.42a3.5 3.5 0 101.414 1.414l8.128-8.127a1 1 0 00-1.414-1.414L10 8.586l-1.42-1.42A3.5 3.5 0 005.5 2zM4 5.5a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zm0 9a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0z" clip-rule="evenodd"/><path d="M12.828 11.414a1 1 0 00-1.414 1.414l3.879 3.88a1 1 0 001.414-1.415l-3.879-3.879z"/></svg>
          <h2 class="text-left sm:text-left md:text-center text-3xl md:text-4xl font-extrabold mb-4 m-0-auto max-w-full sm:max-w-full md:max-w-28-rem lg:max-w-32-rem xl:max-w-52-rem">
          { header }
          </h2>
          <h3 class="text-left sm:text-left md:text-center text-lg md:text-xl md:leading-relaxed font-medium text-gray-600 max-w-[19em] lg:w-2/3 mx-auto">
          {description.description}
          </h3>
          <div class="flex flex-col sm:flex-row sm:items-center sm:justify-center space-y-2 sm:space-y-0 sm:space-x-2 pt-10 pb-16">
          {primaryUrl ?  (
            <Button href={primaryUrl}>
              {primaryCtaText ? <span>{primaryCtaText}</span> : <span>"Learn More"</span>}
              <svg class="hi-solid hi-adjustments inline-block w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M5 4a1 1 0 00-2 0v7.268a2 2 0 000 3.464V16a1 1 0 102 0v-1.268a2 2 0 000-3.464V4zM11 4a1 1 0 10-2 0v1.268a2 2 0 000 3.464V16a1 1 0 102 0V8.732a2 2 0 000-3.464V4zM16 3a1 1 0 011 1v7.268a2 2 0 010 3.464V16a1 1 0 11-2 0v-1.268a2 2 0 010-3.464V4a1 1 0 011-1z"/></svg>
            </Button>
          ) : null}
            {secondaryUrl ?  (
              <Button theme={`LAUNCH-DARK`} href={secondaryUrl}>
                {secondaryCtaText ? <span>{secondaryCtaText}</span> : <span>"Learn More"</span>}
                <svg class="hi-solid hi-arrow-narrow-right inline-block w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd"/></svg>
              </Button>
            ): null }
        </div>
        <div class={`relative
          m-0-auto
          max-w-10-rem sm:max-w-10-rem md:max-w-15-rem xl:max-w-21-rem`}>
          {image && (
              <Image
                image={image.localFile.childImageSharp.gatsbyImageData}
              />
            )}
        </div>
        </div>
      </div>
    </div>
  )

}

export default Hero
