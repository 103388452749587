import React, { useCallback } from "react"
import { ProductCard } from "../../Shared"
import { useIntersectionObserver } from "../../../hooks"
const TopProducts = ({ data }) => {
  const [observerRef, onScreen] = useIntersectionObserver()

  const setRefs = useCallback(
    ref => {
      observerRef.current = ref
    },
    [observerRef]
  )
  return (
    <div className="relative container xl:max-w-7xl mx-auto px-4 py-16 lg:px-8 lg:py-32">
      <div className="bg-gray-100 absolute -right-full -left-full inset-y-0" />
      <div className="relative">
        <h2 class="text-3xl md:text-4xl font-extrabold mb-10 text-gray-600">
          Top Products
        </h2>
        <div
          class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-4 lg:gap-8"
          ref={setRefs}
        >
          {data.contentItems && data.contentItems.nodes.length > 0 ? (
            data.contentItems.nodes.map((val, index) => (
                <ProductCard {...val} index={index} onScreen={onScreen}/>
              )
            )
          ) : (
            <div>No products found.</div>
          )}
        </div>
      </div>
    </div>
  )
}

export default TopProducts
